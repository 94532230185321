<template>
  <div class="demo">
    <div class="msg">
      msg:{{ msg }}
      <el-input ref="scanInput" v-model="handoverSeq" style="width: 50%;height: 60px;" @blur="inputblur()"></el-input>
    </div>
    <button @click="showModal">打开弹窗</button>
    <div v-if="isModalVisible">
      <div class="modal-overlay" @click="hideModal"></div>
      <div class="modal">
        <iframe :src="externalUrl"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { jsonPost, formPost } from '@/baseAPI'

export default {
  data() {
    return {
      msg: '',
      handoverSeq: '',
      isModalVisible: false,
      externalUrl: 'http://157.122.102.223:8012/medical-web/q/ojnOLHhheehjliiijjihnhll/82688'
    }
  },
  // 方法一
  created() {
    var b = ''
    var _this = this
    document.onkeydown = function(event) {
      console.log('event', event)
      if (event.keyCode != 13) {
        var bizCode = String.fromCharCode(event.keyCode)
        if (event.keyCode >= 48 && event.keyCode <= 122) {
          b = b + bizCode
        }
      } else {
        b = ''
      }
      _this.msg = b
    }
    // 方法二1
    this.$nextTick(() => {
      this.$watch(
        'handoverSeq',
        this.debounce(newQuery => {
          if (this.handoverSeq) {
            console.log('扫码获取的交接流水号', this.handoverSeq)
            // this.sendHandoverReceive(newQuery);//接口调用方法
            this.handoverSeq = ''
          }
        }, 1000)
      )
    })
  },

  methods: {
    showModal() {
      this.isModalVisible = true
    },
    hideModal() {
      this.isModalVisible = false
    },

    async getClinicPaidRecordsDetail() {
      const { data: res } = await jsonPost('/outpatient/getClinicPaidRecordsDetail', {
        patCardType: '1',
        patCardNo: '100803589',
        agtOrdNum: '2305221642254884',
        orderNum: '202305222071'
      })
    },

    // 初始获取焦点
    keyPress() {
      // nextTik 针对DOM 没有渲染出现Undefined问题
      this.$nextTick(() => {
        this.$refs.scanInput.focus()
      })
    },
    // 失去焦点
    inputblur() {
      let that = this
      // FireFox 和 IE 失去焦点，blur直接执行focus 不会生效，加个延时
      setTimeout(() => {
        that.$refs.scanInput.focus()
      }, 10)
    },
    //防抖处理
    debounce(func, delay) {
      let timer
      return function(...args) {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          func.apply(this, args)
        }, delay)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 60%;
}

.demo {
  overflow: hidden;
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.msg {
  margin-top: 20%;
  font-size: 45px;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  z-index: 1000;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
